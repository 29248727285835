
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import dashboard from '@/modules/Dashboard';
import { AchievementAwardType, MilestoneType } from '@/api/graphQL/graphNodes/types';
import {
    MILESTONES_TOOLTIP_TYPES, MILESTONE_CATEGORY, MILESTONE_KEY, MILESTONE_STATE_METRIC,
    MILESTONE_TYPE,
} from '@/modules/Dashboard/constants';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import Achievement from '@/projectComponents/achievement/index.vue';
import AchievementManager from '@/projectComponents/achievement/achievementManager';
import dateManager from '@/utils/time';
import { defaultMilestone } from '@/modules/Dashboard/defaults';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';
import MilestoneItem from '../Components/MilestoneItem/index.vue';

@Component({
    components: {
        Achievement,
        MilestoneItem,
        InfoIcon,
    },
})
export default class PathwayTravelIncentive extends Vue {
    @Get(dashboard, 'data.nextSeason') private nextSeason!: MilestoneType[];

    @Get(dashboard) private awards!: AchievementAwardType[];

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    get travelIncentive(): MilestoneType|null {
        const res = this.nextSeason
            .find((item) => item.key === MILESTONE_KEY.fullIncentiveCriteria);

        return res || null;
    }

    get overallIncentive(): MilestoneType {
        const award: AchievementAwardType|undefined = this.awards
            .find((awardItem) => awardItem.type === WildcardManager
                .resolveAwardType(MILESTONE_KEY.fakeTravel));

        return {
            ...defaultMilestone(),
            key: MILESTONE_KEY.fakeTravel,
            category: MILESTONE_CATEGORY.travel,
            type: MILESTONE_TYPE.travel,
            state: [
                {
                    metric: MILESTONE_STATE_METRIC.leaderboardPoints,
                    value: award ? `${award.leaderboard[0].metricValue}` : '',
                },
            ],
        };
    }

    get travelIncentives(): MilestoneType[] {
        const { travelIncentive } = this;

        if (travelIncentive === null) {
            return [];
        }

        const res: MilestoneType[] = [
            { ...travelIncentive },
            { ...travelIncentive },
            { ...travelIncentive },
        ];

        return res.map((item, index) => {
            if (this.currentSeason > index) {
                return {
                    ...item,
                    achieved: !travelIncentive.unachievable,
                };
            }

            if (this.currentSeason === index) {
                return {
                    ...travelIncentive,
                };
            }

            return {
                ...item,
                achieved: false,
            };
        });
    }

    get currentSeason(): number {
        const currentYear = dateManager.getCurrentDate({ returnFormat: 'YYYY' });
        const currentDate = dateManager.getCurrentDate();

        if (
            dateManager.isAfter(currentDate, dateManager.getDateTime(`${currentYear}-01-01 00:00:00`))
            && dateManager.isBefore(currentDate, dateManager.getDateTime(`${currentYear}-04-30 23:59:59`))
        ) {
            return 0;
        } if (
            dateManager.isAfter(currentDate, dateManager.getDateTime(`${currentYear}-05-01 00:00:00`))
            && dateManager.isBefore(currentDate, dateManager.getDateTime(`${currentYear}-08-31 23:59:59`))
        ) {
            return 1;
        } if (
            dateManager.isAfter(currentDate, dateManager.getDateTime(`${currentYear}-09-01 00:00:00`))
        ) {
            return 2;
        }

        return 0;
    }

    get travelIcon() {
        return `${env.BASE_URL}img/icons/card_travel.svg`;
    }

    get travelGreyIcon() {
        return `${env.BASE_URL}img/icons/card_travel_grey.svg`;
    }

    get tooltipText() {
        return WildcardManager.pathwayTooltipResolver(MILESTONES_TOOLTIP_TYPES.travel);
    }

    status(milestone: MilestoneType): string {
        return AchievementManager.resolveStatus(milestone);
    }
}
