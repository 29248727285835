
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Loader extends Vue {
    @Prop({ default: '' }) private type!: string;

    @Prop({ default: 'polite' }) private ariaLive!: 'polite' | 'off' | 'assertive';

    @Prop({ default: 'status' }) private role!: string;

    @Prop({ default: 'loading' }) private ariaLabel!: string;

    get classes() {
        return [
            'Loader',
            { Loader__Tiny: this.type === 'tiny' },
        ];
    }
}
