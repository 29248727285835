
import { Vue, Component, Prop } from 'vue-property-decorator';
import AchievementManager from './achievementManager';

@Component({})
export default class PathwayAchievement extends Vue {
    @Prop({ default: '' }) private label!: string;

    @Prop() private value!: string|number;

    @Prop() private status!: string;

    @Prop({ default: 'regular' }) size!: string;

    @Prop({ default: 'primary' }) private type!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: '' }) private svgSrc!: string;

    get statusClass() {
        const { status, type } = this;

        const typeClass = type !== 'primary' ? `PathwayAchievement__Medal--${type}` : '';
        return `PathwayAchievement__Medal--${status} ${typeClass}`;
    }

    get containerClasses() {
        const { size } = this;
        const sizeClass = size !== 'regular' ? `PathwayAchievement__Container--${size}` : '';
        return [
            'PathwayAchievement__Container',
            sizeClass,
        ];
    }

    get ariaLabel(): string {
        return AchievementManager.resolveAreaLabel(this.status);
    }
}
