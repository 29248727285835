
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { MILESTONES_TOOLTIP_TYPES, MILESTONE_KEY, MILESTONE_STATE_METRIC } from '@/modules/Dashboard/constants';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import Achievement from '@/projectComponents/achievement/index.vue';
import { defaultMilestone } from '@/modules/Dashboard/defaults';
import AchievementManager from '@/projectComponents/achievement/achievementManager';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        Achievement,
        InfoIcon,
    },
})
export default class Club250 extends Vue {
    @Get(dashboard, 'data.nextSeason') private nextSeason!: MilestoneType[];

    get milestone(): MilestoneType {
        return this.nextSeason
            .find((item) => item.key === MILESTONE_KEY.club250) || defaultMilestone();
    }

    get tooltipText() {
        return WildcardManager.pathwayTooltipResolver(MILESTONES_TOOLTIP_TYPES.wildcard);
    }

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    resolveLabel(type) {
        return WildcardManager.wildcardLabelResolver(type);
    }

    get status(): string {
        return AchievementManager.resolveStatus(this.milestone);
    }

    getMilestoneValue(): number {
        const entries = this.milestone.state
            .find((item) => item.metric === MILESTONE_STATE_METRIC.entries);

        return entries ? Number(entries.value) : 0;
    }
}
