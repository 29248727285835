
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboardStats from '@/modules/Dashboard/stats';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType } from '@/api/graphQL/graphNodes/types';
import risingStars, { RisingStars } from '@/modules/RisingStars';
import { DIRECTION_DESC } from '@/modules/constants';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class CTBV extends Vue {
    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private active!: boolean;

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.gv;
    }

    get gv(): number {
        const stat = this.stats.find((item) => item.metric === this.metric);
        return !stat ? 0 : Number(stat.value);
    }

    get ctbvRequirement(): string | null {
        const gv = this.requirements.find((item) => item.metric === this.metric);

        return gv ? gv.value : null;
    }

    get progressValue(): number|null {
        const x = this.gv * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.ctbvRequirement) {
            return null;
        }

        if (Number(this.ctbvRequirement) === 0) {
            return 0;
        }

        return x / Number(this.ctbvRequirement);
    }

    handleRedirect() {
        risingStars.setType(RisingStars.TYPE_TYPES.ps);
        risingStars.setDirection(DIRECTION_DESC);
        this.$router.push({ name: 'myTeam' });
    }
}
