
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import dashboardStats from '@/modules/Dashboard/stats';
import downlineExplorer from '@/modules/DownlineExplorer';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class OBV extends Vue {
    @Get(dashboardStats) stats!: RankRequirementsType[];

    @Prop() rank!: RankType;

    @Prop() requirements!: RankRequirementType[];

    @Prop() private active!: boolean;

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.ov;
    }

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get ov(): number {
        const stat = this.stats.find((item) => item.metric === this.metric);
        return !stat ? 0 : Number(stat.value);
    }

    get ovRequirement(): string|null {
        const ov = this.requirements
            .find((item) => item.metric === this.metric);

        return ov ? ov.value : null;
    }

    get progressValue(): number|null {
        const x = this.ov * 100;

        if (this.requirements.length === 0) {
            return null;
        }

        if (!this.ovRequirement) {
            return null;
        }

        if (Number(this.ovRequirement) === 0) {
            return 0;
        }

        return x / Number(this.ovRequirement);
    }

    handleRedirect() {
        downlineExplorer.setFilter(null);
        this.$router.push({ name: 'myDownline' });
    }
}
