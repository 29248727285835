import { MilestoneType } from '@/api/graphQL/graphNodes/types';

class AchievementManager {
    resolveStatus(milestone: MilestoneType): string {
        if (milestone.achieved) {
            return 'completed';
        }

        if (milestone.unachievable) {
            return 'unachievable';
        }

        return 'achievable';
    }

    resolveAreaLabel(status: string) {
        let res;

        switch (status) {
        case 'completed':

            res = 'completed';
            break;
        case 'unachievable':

            res = 'unachievable';
            break;

        default:
            res = 'achievable';
            break;
        }

        return res;
    }
}

export default new AchievementManager();
