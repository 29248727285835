
import { PV_THRESHOLD } from '@/modules/constants';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import { Vue, Component } from 'vue-property-decorator';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        InfoIcon,
    },
})
export default class BusinessShapeLegend extends Vue {
    get pvThreshold() {
        return PV_THRESHOLD;
    }

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }
}
