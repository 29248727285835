
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import componentsControls from '@/modules/ComponentsControls';
import countries from '@/modules/Countries';
import { EVENT_CREATED_MESSAGE } from '@/modules/Event/Events/messages';
import eventCreate from '@/modules/Event/EventCreate';
import { EVENT_HOST_TYPE } from '@/modules/Event/constants';
import { CreateEventResultType } from '@/api/graphQL/graphNodes/typesResult';
import NoHostess from '@/views/Events/Components/HostContent/noHostess.vue';
import HostessMe from '@/views/Events/Components/HostContent/hostessMe.vue';
import KnownHostess from '@/views/Events/Components/HostContent/knownHostess.vue';
import NewHostess from '@/views/Events/Components/HostContent/newHostess.vue';
import { ErrorType } from '@plumtreesystems/utils';
import EventHostessForm from '@/views/Events/Components/HostessForm/index.vue';
import { EnrolleeType } from '@/api/graphQL/graphNodes/types';
import { CreateEventHostessType } from '@/modules/Event/Events/types';
import env from '@/environment';
import auth from '@/modules/Auth';
import AgreedToReceiveEmail from '@/projectComponents/agreedToReceiveEmail/index.vue';
import addressSelect from '@/modules/AddressSelect';
import { FORM_ERROR_TOOLTIP } from '@/modules/constants';
import EventCreateForm from './eventCreateForm.vue';

@Component({
    components: {
        HostessMe,
        KnownHostess,
        NewHostess,
        EventCreateForm,
        EventHostessForm,
        NoHostess,
        AgreedToReceiveEmail,
    },
})
export default class EventCreateView extends Vue {
    @Get(auth) private suspended!: boolean;

    @Get(eventCreate) private eventHostType!: number;

    @Get(eventCreate) private loading!: boolean;

    @Get(eventCreate) private redirectUrl!: string|null;

    @Get(eventCreate) private displayTooltip!: boolean;

    @Sync(eventCreate) private hostess!: EnrolleeType;

    @Get(eventCreate, 'eventFormErrors') private formErrors!: ErrorType;

    @Get(eventCreate, 'hostessFormErrors') private hostessFormErrors!: ErrorType;

    @Get(countries, 'loading') private countriesLoading!: boolean;

    @Get(eventCreate, 'eventData.type') private type!: string;

    private agreeToReceiveEmail = false;

    get eventHostMe() {
        return EVENT_HOST_TYPE.me;
    }

    get eventHostKnown() {
        return EVENT_HOST_TYPE.known;
    }

    get eventHostNew() {
        return EVENT_HOST_TYPE.new;
    }

    get eventNoHost() {
        return EVENT_HOST_TYPE.no;
    }

    get allHostessFormErrors() {
        const { hostessFormErrors, formErrors } = this;
        return { ...hostessFormErrors, ...formErrors };
    }

    get isLoading(): boolean {
        return this.loading || this.countriesLoading;
    }

    get errorMessage(): string {
        return FORM_ERROR_TOOLTIP.errorMessage;
    }

    async handleSave() {
        try {
            const eventData: CreateEventResultType = await eventCreate.eventFormSubmit();
            eventCreate.clearEventCreate();
            if (this.redirectUrl) {
                window.open(this.redirectUrl, '_self');
                eventCreate.setRedirectUrl();
            } else {
                this.$router.push({ name: 'eventView', params: { id: eventData.createEvent.id } });
                componentsControls.showSuccessMessage({ message: EVENT_CREATED_MESSAGE });
            }
        // eslint-disable-next-line no-empty
        } catch (e) {}
    }

    handleInputUpdate(val: CreateEventHostessType) {
        eventCreate.setHostess(val);
    }

    unlockedAfterStage(stage: number): boolean {
        return Number(env.VUE_APP_STAGE) > stage;
    }

    beforeDestroy() {
        eventCreate.setDisplayTooltip(false);
    }

    created() {
        if (this.type === '') {
            this.$router.push({ name: 'createEventType' });
        } else if (this.eventHostType === EVENT_HOST_TYPE.undefined) {
            this.$router.push({ name: 'createEventHostess' });
        }
    }

    async beforeMount() {
        countries.getCountries();
        addressSelect.resetData();

        if (this.eventHostType === EVENT_HOST_TYPE.me) {
            await eventCreate.setHostessMe();
        }
    }
}
