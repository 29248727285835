
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LoaderWithOverlay extends Vue {
    @Prop({ default: 'polite' }) private ariaLive!: 'polite' | 'off' | 'assertive';

    @Prop({ default: 'status' }) private role!: string;

    @Prop({ default: 'loading' }) private ariaLabel!: string;
}
