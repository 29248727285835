
import { AchievementAwardType } from '@/api/graphQL/graphNodes/types';
import { Vue, Component, Prop } from 'vue-property-decorator';
import env from '@/environment';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import ranking from '@/modules/Dashboard/ranking';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        InfoIcon,
    },
})
export default class AchievementAwardItem extends Vue {
    @Prop() private empty!: boolean;

    @Prop() private award!: AchievementAwardType;

    @Prop() private limit!: number;

    @Prop() private screenType!: string;

    get icon(): string {
        const achievement = this.award.leaderboard[0];

        if (achievement) {
            const { position, previousPosition } = achievement;

            return WildcardManager.resolveDirectionIcon(position, previousPosition, env.BASE_URL);
        }

        return WildcardManager.resolveDirectionIcon(0, 0, env.BASE_URL);
    }

    get position(): string|number {
        const achievement = this.award.leaderboard[0];

        return achievement && achievement.position ? achievement.position : '';
    }

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    get statusIconAriaLabel() {
        let label = '';
        switch (this.direction) {
        case 'green':
            label = 'moved up';
            break;
        case 'red':
            label = 'moved down';
            break;
        default:
            label = 'no change';
            break;
        }

        return label;
    }

    get tooltipId() {
        return `achievementAwardItemTooltip_${this.award.type}`;
    }

    get isDesktop(): boolean {
        return this.screenType === 'desktop';
    }

    get direction(): string {
        const achievement = this.award.leaderboard[0];
        const position = achievement ? achievement.position : 0;
        const previousPosition = achievement ? achievement.previousPosition : 0;

        return WildcardManager.directionResolver(position, previousPosition);
    }

    get rankingNumberContainerClasses() {
        let colorClass = '';
        switch (this.direction) {
        case 'up':
            colorClass = 'green';
            break;
        case 'down':
            colorClass = 'red';
            break;
        default:
            colorClass = 'orange';
            break;
        }

        return `AchievementAwardItem__StatusText--${colorClass}`;
    }

    handleRedirectData() {
        const achievement = this.award.leaderboard[0];

        if (achievement) {
            const { position } = achievement;

            ranking.setSkip(Math.floor(position / this.limit) * this.limit);
        }

        this.$router.push({ name: 'achievement', params: { type: this.award.type } });
    }
}
