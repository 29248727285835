
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class Tooltip extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: 'tooltip' }) private role!: string;

    @Prop({ default: 0 }) private tabindex!: number;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: null }) private open!: null|boolean;

    @Prop({ default: null }) private inline!: boolean;

    private visible: boolean = false;

    private styles = {};

    get elementClasses() {
        return [
            'Tooltip__Content',
            this.visible ? 'Tooltip__Content--visible' : '',
        ];
    }

    get wrapperClasses() {
        return [
            'Tooltip',
            this.inline ? 'Tooltip--inLine' : '',
        ];
    }

    handleOpen() {
        const { open } = this;

        if (open === null) {
            this.changeVisibility(true);
        }
    }

    handleClose() {
        const { open } = this;

        if (open === null) {
            this.changeVisibility(false);
        }
    }

    @Watch('open')
    changeVisibility(val: boolean) {
        if (!this.disabled) {
            this.visible = val;
        }
    }

    calculatePosition() {
        const tooltip = this.$refs.tooltip! as HTMLElement;
        const parentTooltip = tooltip.parentElement;

        if (parentTooltip) {
            const domRect = tooltip.getBoundingClientRect();
            const parentDomRect = parentTooltip.getBoundingClientRect();
            const res: any = {};
            const { left } = parentDomRect;
            const { innerWidth } = window;

            if ((innerWidth - left) < domRect.width) {
                res.right = 0;
            } else {
                res.left = 0;
            }

            this.styles = { ...res };
        }
    }

    mounted() {
        window.addEventListener('resize', this.calculatePosition);
        this.calculatePosition();
    }
}
