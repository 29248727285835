
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboardStats from '@/modules/Dashboard/stats';
import RankRequirementManager from '@/utils/rankRequirementManager';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import { RankRequirementsType, RankType } from '@/api/graphQL/graphNodes/types';
import StatItemLayout from '../StatItemLayout/index.vue';

@Component({
    components: {
        StatItemLayout,
    },
})
export default class FrontlineLeaders extends Vue {
    @Get(dashboardStats) private stats!: RankRequirementsType[];

    @Prop() private requirements!: RankRequirementType[];

    @Prop() private rank!: RankType;

    @Prop() private active!: boolean;

    get title(): string {
        return RankRequirementManager.getRankRequirementLabel(this.metric).title;
    }

    get metric(): string {
        return RankRequirementManager.rankRequirementMetricConstants.qualifiedFrontlineLeaders;
    }

    get qualifiedFrontlineLeadersCount(): number {
        const stat = this.stats.find((item) => item.metric === this.metric);
        return !stat ? 0 : Number(stat.value);
    }

    get qualifiedFrontlineLeadersRequirement(): RankRequirementType | null {
        const requirement = this.requirements
            .find((item) => item.metric === this.metric);

        return requirement || null;
    }

    get progressValue(): number|null {
        const x = this.qualifiedFrontlineLeadersCount * 100;
        const requirement = this.qualifiedFrontlineLeadersRequirement;

        if (!requirement) {
            return null;
        }

        if (Number(requirement.value) === 0) {
            return 0;
        }

        return x / Number(requirement.value);
    }

    get displayData(): boolean {
        const personalRecruitsCount = this.qualifiedFrontlineLeadersRequirement;

        if (!personalRecruitsCount) {
            return false;
        }

        return true;
    }
}
