
import { Vue, Component } from 'vue-property-decorator';
import { Get, Sync } from '@/utils/vuex-module-mutators';
import { SelectOptionsType } from '@/components/select/types';
import risingStars, { RisingStars } from '@/modules/RisingStars';
import { Debounced } from '@/utils/debounced';
import { RisingStarsType } from '@/api/graphQL/graphNodes/types';
import { DIRECTION_ASC, DIRECTION_DESC } from '@/modules/constants';
import system from '@/modules/System';

@Component({
    components: {},
})
export default class RisingStarsHeader extends Vue {
    @Sync(risingStars) searchQuery!: string;

    @Get(risingStars) type!: string;

    @Get(risingStars) searchLoading!: boolean;

    @Get(risingStars) minSearchLength!: number;

    @Get(risingStars) searchedLegsOptions!: RisingStarsType[];

    @Get(risingStars) displaySearchResults!: boolean;

    @Get(risingStars) direction!: string;

    @Get(risingStars) loading!: boolean;

    @Get(system) screenType!: string;

    get searchOptions(): SelectOptionsType[] {
        return this.searchedLegsOptions.map((leg) => ({
            name: `${leg.firstName} ${leg.lastName}`,
            value: `${leg.firstName} ${leg.lastName}`,
        }));
    }

    get sortTypeOptions(): SelectOptionsType[] {
        return [
            {
                name: 'PS',
                value: RisingStars.TYPE_TYPES.ps,
            },
            {
                name: 'AOF',
                value: RisingStars.TYPE_TYPES.aof,
            },
            {
                name: 'Breakaway',
                value: RisingStars.TYPE_TYPES.breakAway,
            },
        ];
    }

    get searchContainerClasses() {
        return [
            'RisingStarsHeader__Container',
            'pts-layout-item',
            'pts-size-50',
            'pts-xsmall-size-100',
        ];
    }

    get sortContainerClasses() {
        return [
            'RisingStarsHeader__Container',
            'RisingStarsHeader__FilterContainer',
            'pts-layout-item',
            'pts-size-50',
            'pts-xsmall-size-100',
        ];
    }

    get directionContainerClasses() {
        return [
            'RisingStarsHeader__DirectionContainer',
            { 'RisingStarsHeader__DirectionContainer--asc': this.direction === this.asc },
        ];
    }

    get isMobile() {
        return this.screenType === 'mobile';
    }

    get asc(): string {
        return DIRECTION_ASC;
    }

    get desc(): string {
        return DIRECTION_DESC;
    }

    get viewIsLoading(): boolean {
        return this.searchLoading || this.loading;
    }

    handleSort(val) {
        risingStars.setType(val);

        this.loadData();
    }

    loadData() {
        if (this.searchQuery === '') {
            risingStars.getRisingStars({ loadPage: true });
        } else {
            risingStars.search();
        }
    }

    handleSelect(val) {
        risingStars.setSearchQuery(val.value);
        risingStars.setDisplaySearchResults(true);
        risingStars.search({ selectedSearch: true });
    }

    @Debounced(1000)
    handleChange() {
        if (this.searchQuery === '') {
            risingStars.setDisplaySearchResults(false);
            risingStars.setSearchedLegs([]);
            risingStars.setSearchedOptionsLegs([]);
        }
        if (this.searchQuery.length >= this.minSearchLength) { risingStars.search(); }
    }

    handleSortClick() {
        const { direction, asc, desc } = this;

        if (direction === asc) {
            risingStars.setDirection(desc);
        } else {
            risingStars.setDirection(asc);
        }

        this.loadData();
    }
}
