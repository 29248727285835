
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class Icon extends Vue {
    @Prop({ required: true }) private icon!: string;

    @Prop({ default: 'img' }) private role!: string;

    @Prop() private customImage!: string;

    @Prop({ default: false }) private ariaHidden!: boolean;

    @Prop({ default: '' }) private ariaLabel!: string;

    handleClick() {
        this.$emit('click');
    }
}
