
import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';
import { INFO_ICON_ARIA_LABEL } from '../constants';

@Component({
    components: {
        InfoIcon,
    },
})
export default class FormErrorTooltip extends Vue {
    @Prop({ required: true }) private id!: string;

    @Prop({ default: '' }) private message!: string;

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }
}
