
import { quillEditor } from 'vue-quill-editor';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { BrowserDetector, ObjectProcessor } from '@plumtreesystems/utils';
import maskResolver from '@/utils/mask-resolver';

@Component({
    components: {
        quillEditor,
    },
})
export default class TextField extends Vue {
    @Prop({ default: '' }) private id!: string;

    @Prop({ default: '' }) private name!: string;

    @Prop({ default: '' }) private label!: string;

    @Prop({ default: null }) private displayType!: string|null;

    @Prop({ required: true }) private value!: any;

    @Prop({ default: '' }) private error!: string;

    @Prop({ default: true }) private required!: boolean;

    @Prop({ default: '' }) private helperText!: string;

    @Prop({ default: '' }) private prefix!: string;

    @Prop({ default: '' }) private suffix!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: false }) private autocomplete!: boolean;

    @Prop() private maxValue!: number;

    @Prop() private maxLength!: number;

    @Prop() private loading!: boolean;

    @Prop({
        default: TextField.TEXT_FIELD_TYPE.text,
        validator: (val: string) => TextField.TEXT_FIELD_TYPE[val] !== undefined,
    }) private type!: string;

    @Prop({ default: false }) private disabled!: boolean;

    @Prop({ default: '' }) private wrapperClass!: string[];

    @Prop() private editorOptions!: object;

    private containerClass: string[] = [];

    static readonly TEXT_FIELD_TYPE = {
        text: 'text',
        number: 'number',
        textarea: 'textarea',
        texteditor: 'texteditor',
        password: 'password',
    };

    optionalLabel(val: string) {
        return `${val} (optional)`;
    }

    get formattedEditorOptions() {
        return ObjectProcessor.objectMerge({
            modules: { keyboard: { bindings: { tab: true } } },
        },
        this.editorOptions);
    }

    get elementClasses() {
        return [
            'TextField',
            ...this.containerClass,
            this.wrapperClass,
            { 'TextField--error': this.error !== '' },
            { 'TextField--disabled': this.disabled },
            { 'TextField--withIcon': this.icon },
            { TextField__TextareaControl: this.type === 'textarea' },
            { TextField__TexteditorControl: this.type === 'texteditor' },
        ];
    }

    get quillValue() {
        return typeof this.value === 'string' ? { ops: JSON.parse(this.value) } : this.value;
    }

    get autocompleteVal(): string {
        const disabledVal = BrowserDetector.isChrome ? 'disabled' : 'off';
        return this.autocomplete ? 'on' : disabledVal;
    }

    get maskObject() {
        return maskResolver(this.displayType);
    }

    addToContainerClass(items: string[]) {
        const combinedObject = new Set([...this.containerClass, ...items]);
        this.containerClass = [...combinedObject];
    }

    removeFromContainerClass(items: string[]) {
        this.containerClass = this.containerClass.filter((classItem) => !items.includes(classItem));
    }

    elementOnBlur(el: any) {
        this.setActive(el.target, false);
        this.$emit('blur', el);
        this.removeFromContainerClass(['TextField--focused']);
    }

    elementOnFocus(el: any) {
        this.setActive(el.target, true);
        this.$emit('focus', el);
        this.addToContainerClass(['TextField--focused']);
    }

    setActive(el: any, active: boolean) {
        if (active) {
            this.addToContainerClass(['TextField--isPulled']);
        } else if (!el || el.value === '') {
            this.removeFromContainerClass(['TextField--isPulled', 'TextField--isFilled']);
        } else {
            this.addToContainerClass(['TextField--isFilled']);
        }
    }

    created() {
        if (this.type !== 'texteditor') {
            if (this.value !== '') {
                this.addToContainerClass(['TextField--isFilled', 'TextField--isPulled']);
            }
        }
    }

    mounted() {
        if (this.type === TextField.TEXT_FIELD_TYPE.texteditor) {
            this.setQuillContent();
        }
    }

    handleUpdate(val: any) {
        this.$emit('input', val);
    }

    beforeUpdate() {
        if (this.type !== 'texteditor') {
            if (this.value === '') {
                this.removeFromContainerClass(['TextField--isFilled', 'TextField--isPulled']);
            } else {
                this.addToContainerClass(['TextField--isFilled', 'TextField--isPulled']);
            }
        }
    }

    setQuillContent() {
        // @ts-ignore
        this.$refs.quillEditor.quill.setContents(this.quillValue);
    }

    onEditorChange({ quill }) {
        this.$emit('input', quill.editor.delta);
    }
}
