
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import env from '@/environment';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import { MilestoneType } from '@/api/graphQL/graphNodes/types';
import { BUSINESS_DEVELOPMENT_TYPES, MILESTONES_TOOLTIP_TYPES } from '@/modules/Dashboard/constants';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        InfoIcon,
    },
})
export default class PathwayBusinessDevelopment extends Vue {
    @Get(dashboard, 'data.thisSeason') private thisSeason!: MilestoneType[];

    get trophyIcon() {
        return `${env.BASE_URL}img/icons/trophy.svg`;
    }

    get trophyDarkIcon() {
        return `${env.BASE_URL}img/icons/trophy_dark.svg`;
    }

    get trophyGreyIcon() {
        return `${env.BASE_URL}img/icons/trophy_grey.svg`;
    }

    get thisSeasonLabel() {
        return WildcardManager.businessDevelopmentLabelResolver(this.thisSeason);
    }

    get tooltipContent(): string {
        return WildcardManager.pathwayTooltipResolver(MILESTONES_TOOLTIP_TYPES.business);
    }

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    resolveIcon(type) {
        let res = '';

        switch (type) {
        case BUSINESS_DEVELOPMENT_TYPES.silver:
            res = this.trophyDarkIcon;
            break;
        case BUSINESS_DEVELOPMENT_TYPES.gold:
        case BUSINESS_DEVELOPMENT_TYPES.bronze:
            res = this.trophyIcon;
            break;
        default:
            res = this.trophyGreyIcon;
            break;
        }

        return res;
    }
}
