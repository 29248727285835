
import { Vue, Component, Prop } from 'vue-property-decorator';
import system from '@/modules/System';
import { Get } from '@/utils/vuex-module-mutators';
import CurrencyPrefix from '@/utils/currencyPrefix';
import { COUNTRY_SELECT_OPTIONS } from '@/modules/constants';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        InfoIcon,
    },
})
export default class StatItemLayout extends Vue {
    @Prop() private value!: number;

    @Prop({ default: null }) private progressValue!: number|null;

    @Prop({ default: '' }) private requirementType!: string;

    @Prop() private tooltipText!: string;

    @Prop() private title!: string;

    @Prop({ default: '' }) private icon!: string;

    @Prop({ default: false }) private empty!: boolean;

    @Prop() private link!: any;

    @Prop({ default: false }) private customTooltip!: boolean;

    @Prop() private id!: string;

    @Prop() private displayCurrencyPrefix!: boolean;

    @Prop() private clickableTitle!: boolean;

    @Prop() private active!: boolean;

    @Get(system) private screenType!: string;

    get isDesktop(): boolean {
        return this.screenType === 'desktop';
    }

    get tooltipId() {
        return `statInfoTooltip_${this.id}`;
    }

    get currencyPrefix() {
        return CurrencyPrefix.resolvePrefix(COUNTRY_SELECT_OPTIONS.gb);
    }

    get titleClasses() {
        return [
            { 'StatItemLayout__Title--clickable': this.clickableTitle },
        ];
    }

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    handleTitleClick() {
        if (this.clickableTitle) {
            this.$emit('titleClick');
        }
    }
}
