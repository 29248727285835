var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Scene',{attrs:{"route":"createEventDetails","id":"eventCreateFormScene","aria-busy":_vm.isLoading}},[(!_vm.isLoading)?_c('div',{staticClass:"pts-layout pts-gutter"},[_c('div',{staticClass:"pts-layout-item pts-size-100"},[_c('div',{staticClass:"Ui__Card pts-layout pts-gutter"},[_c('div',{staticClass:"pts-layout pts-layout-item pts-size-100"},[_c('div',{staticClass:"CreateEvent__BackButtonHeader Ui__CardHeader pts-layout-item pts-size-100"},[_c('BackButton'),_c('h4',[_vm._v("Create an Event")])],1),_c('div',{staticClass:"pts-layout-item pts-size-100"},[_c('EventCreateForm')],1),(_vm.eventHostType !== _vm.eventHostMe &&
                            _vm.eventHostType !== _vm.eventNoHost)?_c('div',{staticClass:"EventCreate__HostType pts-layout-item pts-size-100"},[_c('div',{staticClass:"pts-layout-item pts-size-100"},[_c('h4',{staticClass:"EventCreateForm__DetailsHeader"},[_vm._v(" Who is hosting the event? ")]),(_vm.eventHostType !== _vm.eventHostMe &&
                                 _vm.eventHostType !== _vm.eventNoHost)?_c('EventHostessForm',{attrs:{"hostess":_vm.hostess,"formErrors":_vm.allHostessFormErrors,"eventHostType":_vm.eventHostType},on:{"input":_vm.handleInputUpdate}}):_vm._e()],1)]):_vm._e()]),_c('div',{staticClass:"pts-layout pts-layout-item pts-size-100"},[_c('div',{staticClass:"pts-layout-item pts-size-100 pts-layout"},[(_vm.eventHostType === _vm.eventHostNew
                             || _vm.eventHostType === _vm.eventHostKnown)?_c('AgreedToReceiveEmail',{model:{value:(_vm.agreeToReceiveEmail),callback:function ($$v) {_vm.agreeToReceiveEmail=$$v},expression:"agreeToReceiveEmail"}}):_vm._e()],1),_c('div',{staticClass:"pts-layout-item pts-size-100 pts-layout EventCreate__ButtonContainer"},[(!_vm.unlockedAfterStage(1))?_c('Tooltip',{attrs:{"id":"eventCreateTooltip","ariaLabel":"Save"},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('Button',{attrs:{"disabled":_vm.suspended
                                    || ((_vm.eventHostType === _vm.eventHostNew
                                    || _vm.eventHostType === _vm.eventHostKnown)
                                    && !_vm.agreeToReceiveEmail),"type":"contained","text":"Save"},on:{"click":_vm.handleSave}})]},proxy:true},{key:"tooltip",fn:function(){return [_vm._v(" Feature will be available at a later time. ")]},proxy:true}],null,false,3499595853)}):_c('Tooltip',{attrs:{"id":"createEventErrorTooltip","ariaLabel":"Save","open":_vm.displayTooltip},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('Button',{attrs:{"disabled":_vm.suspended
                                    || ((_vm.eventHostType === _vm.eventHostNew
                                    || _vm.eventHostType === _vm.eventHostKnown)
                                    && !_vm.agreeToReceiveEmail),"type":"contained","text":"Save"},on:{"click":_vm.handleSave}})]},proxy:true},{key:"tooltip",fn:function(){return [_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]},proxy:true}],null,false,1771856134)})],1)])])])]):_c('div',{staticClass:"Ui__LoaderContainer"},[_c('Loader')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }