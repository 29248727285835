
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { RunTotalType } from '@/api/graphQL/graphNodes/types';
import { MILESTONES_TOOLTIP_TYPES } from '@/modules/Dashboard/constants';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import Achievement from '@/projectComponents/achievement/index.vue';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        Achievement,
        InfoIcon,
    },
})
export default class Club250 extends Vue {
    @Get(dashboard) private runTotalsForPeriod!: RunTotalType[];

    get tooltipText() {
        return WildcardManager.pathwayTooltipResolver(MILESTONES_TOOLTIP_TYPES.wildcard);
    }

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    resolveLabel(type) {
        return WildcardManager.wildcardLabelResolver(type);
    }

    get status(): string {
        return 'achievable';
    }

    getMilestoneValue(): number {
        return this.runTotalsForPeriod
            .filter((item) => Number(item.enrolleeMetrics.pv) >= 250).length;
    }
}
