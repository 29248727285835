
import {
    Vue, Component, Prop,
} from 'vue-property-decorator';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';
import { INFO_ICON_ARIA_LABEL } from '../constants';

@Component({
    components: {
        InfoIcon,
    },
})
export default class AgreedToReceiveEmail extends Vue {
    @Prop({ required: true }) private value!: boolean;

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    handleValue(value: boolean) {
        this.$emit('input', value);
    }
}
