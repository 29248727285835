
import { Vue, Component, Prop } from 'vue-property-decorator';
import RankRequirementsItem from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/item.vue';
import { RankType } from '@/api/graphQL/graphNodes/types';
import { RankRequirementData } from '@/projectComponents/enrolleeLegItem/Item/RankRequirements/types';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        RankRequirementsItem,
        InfoIcon,
    },
})
export default class NewStartersContentRequirements extends Vue {
    @Prop() private label!: string;

    @Prop() private requirements!: RankRequirementData[];

    @Prop() private tooltipText!: string;

    @Prop() private ranks!: RankType[];

    @Prop() private isMobile!: boolean;

    @Prop() private index!: string;

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }

    get tooltipId() {
        return `newStartersRequirementTooltip${this.index}`;
    }
}
