
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import { Vue, Component, Prop } from 'vue-property-decorator';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';

@Component({
    components: {
        InfoIcon,
    },
})
export default class EventsCompleted extends Vue {
    @Prop({ required: true }) private thisMonth!: number;

    @Prop({ required: true }) private lastMonth!: number;

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }
}
