
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RankType } from '@/api/graphQL/graphNodes/types';
import rank from '@/modules/Rank';
import { Get } from '@/utils/vuex-module-mutators';
import { rank as defaultRank } from '@/modules/Rank/defaults';
import { RankRequirementType } from '@/utils/graphql-mock-server/types';
import BonusQualifiedTeam from './BonusQualifiedTeam/index.vue';
import Rank from './Rank/index.vue';
import CTBV from './CTBV/index.vue';
import UV from './UV/index.vue';
import OBV from './OBV/index.vue';
import PBV from './PBV/index.vue';
import PersonalRecruits from './PersonalRecruits/index.vue';
import QualifiedBranches from './QualifiedBranches/index.vue';
import AmbassadorKit from './AmbassadorKit/index.vue';
import QualifiedLeaderLegs from './QualifiedLeaderLegs/index.vue';
import QualifiedLeaders from './QualifiedLeaders/index.vue';
import FrontlineLeaders from './FrontlineLeaders/index.vue';
import ActiveOnFilePartners from './ActiveOnFilePartners/index.vue';
import StatItemLayout from './StatItemLayout/index.vue';

@Component({
    components: {
        BonusQualifiedTeam,
        CTBV,
        UV,
        OBV,
        PBV,
        PersonalRecruits,
        QualifiedBranches,
        Rank,
        AmbassadorKit,
        QualifiedLeaderLegs,
        QualifiedLeaders,
        FrontlineLeaders,
        ActiveOnFilePartners,
        StatItemLayout,
    },
})
export default class Stats extends Vue {
    @Prop({ required: true }) private rank!: RankType|null;

    @Prop({ required: true }) private rankTitle!: string;

    @Prop({ default: false }) private nextRank!: boolean;

    @Prop({ default: false }) private active!: boolean;

    @Get(rank) private ranks!: RankType[];

    get formattedRank(): RankType {
        let res = { ...defaultRank() };

        if (this.rank) {
            res = { ...this.rank };
        }

        if (!res.requirements || res.requirements === null) {
            res = { ...res, requirements: [] };
        }

        if (!res.titleRequirements || res.titleRequirements === null) {
            res = { ...res, titleRequirements: [] };
        }

        return res;
    }

    get requirements(): RankRequirementType[] {
        return this.nextRank ? this.formattedRank.titleRequirements!
            : this.formattedRank.requirements!;
    }

    get teamAmbassador(): RankType | undefined {
        return this.ranks.find((rankItem) => rankItem.label === 'Team Leader');
    }

    get emptyElementsAmount() {
        const { formattedRank } = this;
        const emptyConst = 3;

        if (formattedRank.requirements) {
            const requirements = formattedRank.requirements
                .filter((item) => item.metric !== 'gv' && item.metric !== 'pv' && item.metric !== 'ov');
            return emptyConst - requirements.length;
        }

        return emptyConst;
    }
}
