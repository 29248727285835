
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import env from '@/environment';
import { AchievementAwardType, MilestoneType } from '@/api/graphQL/graphNodes/types';
import WildcardManager from '@/modules/Dashboard/services/wildcardManager';
import {
    MILESTONE_CATEGORY,
} from '@/modules/Dashboard/constants';
import Achievement from '@/projectComponents/achievement/index.vue';
import AchievementManager from '@/projectComponents/achievement/achievementManager';
import ranking from '@/modules/Dashboard/ranking';

@Component({
    components: {
        Achievement,
    },
})
export default class PathwayMilestoneItem extends Vue {
    @Get(ranking) private limit!: number;

    @Prop() private milestone!: MilestoneType;

    @Prop() private awards!: AchievementAwardType[];

    @Prop({ default: false }) private beautifyValue!: boolean;

    get travelIcon() {
        return `${env.BASE_URL}img/icons/card_travel.svg`;
    }

    get travelGreyIcon() {
        return `${env.BASE_URL}img/icons/card_travel_grey.svg`;
    }

    getAward(milestone: MilestoneType): AchievementAwardType {
        return this.awards.find((awardItem) => awardItem.type
             === this.resolveAwardFromMilestoneType(milestone.key))!;
    }

    icon(wildcard: MilestoneType): string {
        return this.status(wildcard) === 'unachievable' ? this.travelGreyIcon : this.travelIcon;
    }

    resolveLabel(type) {
        return WildcardManager.wildcardLabelResolver(type);
    }

    status(milestone: MilestoneType): string {
        if (this.isTravelMilestone(milestone)) {
            const award = this.getAward(milestone);

            if (award) {
                return award.leaderboard.length > 0
                    && WildcardManager.resolveWildcardSize(milestone.key) >= award.leaderboard[0].position ? 'completed' : 'achievable';
            }

            return 'achievable';
        }
        return AchievementManager.resolveStatus(milestone);
    }

    isTravelMilestone(milestone: MilestoneType): boolean {
        return milestone.category === MILESTONE_CATEGORY.travel;
    }

    getMilestoneValue(milestone: MilestoneType): string {
        const award = this.getAward(milestone);

        return award && award.leaderboard && award.leaderboard.length !== 0 ? String(award.leaderboard[0].position) : 'X';
    }

    isWithRedirect(type: string): boolean {
        return WildcardManager.resolveWildcardRedirect(type);
    }

    resolveAwardFromMilestoneType(type: string): string {
        return WildcardManager.resolveAwardType(type);
    }

    calculateItemClasses(type: string) {
        return [
            'PathwayMilestoneItem__Item',
            { 'PathwayMilestoneItem__Item--clickable': this.isWithRedirect(type) },
        ];
    }

    handleRedirect(milestone: MilestoneType) {
        if (this.isWithRedirect(milestone.key)) {
            const award = this.getAward(milestone);
            const achievement = award.leaderboard[0];

            if (achievement) {
                const { position } = achievement;

                ranking.setSkip(Math.floor(position / this.limit) * this.limit);
            }

            this.$router.push({ name: 'achievement', params: { type: this.resolveAwardFromMilestoneType(milestone.key) } });
        }
    }
}
