
import { Vue, Component } from 'vue-property-decorator';
import { Get } from '@/utils/vuex-module-mutators';
import dashboard from '@/modules/Dashboard';
import { AchievementAwardType, MilestoneType } from '@/api/graphQL/graphNodes/types';
import { MILESTONE_TYPE } from '@/modules/Dashboard/constants';
import { INFO_ICON_ARIA_LABEL } from '@/projectComponents/constants';
import InfoIcon from '@/projectComponents/infoIcon/index.vue';
import MilestoneItem from '../Components/MilestoneItem/index.vue';

@Component({
    components: {
        MilestoneItem,
        InfoIcon,
    },
})
export default class PathwayWildcardProgress extends Vue {
    @Get(dashboard, 'data.nextSeason') private nextSeason!: MilestoneType[];

    @Get(dashboard) private awards!: AchievementAwardType[];

    get wildcardList(): MilestoneType[] {
        return this.nextSeason.filter((item) => item.type === MILESTONE_TYPE.wildcard);
    }

    get infoIconAriaLabel(): string {
        return INFO_ICON_ARIA_LABEL;
    }
}
